<template>
  <div
    class="col-md-4"
    v-for="(program, programIndex) in programs"
    :key="programIndex"
  >
    <div class="event-card">
      <router-link :to="{ name: 'course', params: { slug: program.slug } }">
        <div class="_image-wrap">
          <img :src="program.image" alt="" />
        </div>
        <div class="_body-wrap">
          <h6>{{ program.title }}</h6>
          <span>{{ statndard }}</span>
          <span class="float-right">₹ {{ program.price.price }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseCard",
  props: ["programs", "statndard"],
  components: {}
};
</script>

<style lang="scss">
@import "@/style/event-card.scss";
span.float-right {
  float: right;
}
</style>
