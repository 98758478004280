<template>
  <div class="listing">
    <div class="container">
      <h3>Courses</h3>
    </div>
    <div class="container-fluid sort-row">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-md-3 col-sm-6">
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                v-model="search"
                @change="resetProgramsFetch"
                class="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <select
              class="form-select"
              v-model="select"
              @change="resetProgramsFetch"
              aria-label="Default select example"
            >
              <option value="">Select Standard</option>
              <option
                v-for="(standard, standardIndex) in standards"
                :key="standardIndex"
                :value="standard.id"
                >{{ standard.title }}</option
              >
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="content-wrapper">
            <div v-if="isClassLoader == false">
              <div class="row">
                <CourseCard
                  v-for="(standard, classIndex) in classes"
                  :key="classIndex"
                  :programs="standard.programs"
                  :statndard="standard.title"
                />
              </div>
            </div>
            <div class="row" v-else>
              <WebinarLoader></WebinarLoader>
            </div>
            <empty-list
              v-if="isEmpty == true && isClassLoader == false"
            ></empty-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/CourseCard.vue";
import HomeService from "@/services/HomeService.js";
import errorLog from "@/errorLog";
//import VPagination from "vue3-pagination";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import EmptyList from "@/components/EmptyList.vue";
export default {
  name: "CourseListing",
  components: {
    CourseCard,
    //VPagination,
    WebinarLoader,
    EmptyList
  },
  data() {
    return {
      classes: [],
      isClassLoader: true,
      page: 1,
      search: "",
      select: "",
      isEmpty: true,
      standards: ""
    };
  },
  created() {
    this.loadClasses();
    this.loadStandards();
  },
  methods: {
    resetProgramsFetch() {
      this.loadClasses();
    },
    async loadClasses() {
      const params = {
        standard_id: this.select,
        search: this.search
      };
      this.isClassLoader = true;
      await HomeService.getStandards(params)
        .then(result => {
          this.isClassLoader = false;
          this.classes = result.data;
          this.checkEmptyStatus();
        })
        .catch(error => {
          this.isClassLoader = false;
          errorLog.log(error);
        });
    },
    async loadStandards() {
      await HomeService.getStandardList()
        .then(result => {
          this.isClassLoader = false;
          this.standards = result.data;
        })
        .catch(error => {
          this.isClassLoader = false;
          errorLog.log(error);
        });
    },
    checkEmptyStatus() {
      this.isEmpty = true;
      var programCount = [];
      this.classes.forEach(function(value) {
        if (value.programs.length > 0) {
          programCount.push(1);
        }
      });
      if (programCount.length > 0) {
        this.isEmpty = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/style/listing.scss";
img.webinar-loader.mx-auto.d-block[data-v-7846160e] {
  width: 100px;
  margin-top: 1% !important;
}
</style>
